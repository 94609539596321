import { dollarFieldFormatter } from "../BootstrapTableExtensions.js";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { isAttributeTrue } from "../JavaScriptFunctions";
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";

class BondDocumentsPostedGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="bond-documents-booked-out"></bootstrap-report-grid>',
            [],
            []
        );
        this._pageTools = new PageTools();
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapReportGrid = this.shadowRoot.querySelector("bootstrap-report-grid");
        this._bootstrapTable = this._bootstrapReportGrid.shadowRoot.querySelector("bootstrap-table");
        this._table = this._bootstrapTable.shadowRoot.querySelector("table");
        this._errorMessage = this._bootstrapReportGrid.shadowRoot.getElementById("error-message");
    }

    get _preventBondCompaniesFromReceivingOrViewingSignedBondDocuments() {
        return isAttributeTrue(this, "prevent-bond-companies-from-receiving-or-viewing-signed-bond-documents");
    }

    get _offensesHaveSpecialConditions() {
        return isAttributeTrue(this, "offenses-have-special-conditions");
    }

    get _showCourtNameTemp() {
        return isAttributeTrue(this, "show-court-name-temp");
    }

    get _isCountyUser() {
        return isAttributeTrue(this, "is-county-user");
    }

    get _isSysAdmin() {
        return isAttributeTrue(this, "is-sysadmin");
    }

    get _hasBookingSheetFeatureFlag() {
        return isAttributeTrue(this, "has-booking-sheet-feature");
    }
    get _amendBondDocumentsPermission() {
        return isAttributeTrue(this, "amend-bond-document-permission");
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, "days").format("YYYY-MM-DDT00:00"), moment().format("YYYY-MM-DDT23:59"));
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(this._tableConfig);
    }

    _compileColumns() {
        const operateEvents = {
            'click .check-for-document': this._checkForDocumentOnClick.bind(this),
            'click .view-booking': function (e, value, row, index) {
                const viewEvent = new CustomEvent('viewClick',
                    {
                        detail: {
                            event: e
                        }
                    }
                );
                const table = e.currentTarget.closest('table');
                table.dispatchEvent(viewEvent);
            }
        };

        this._tableColumns = [
            {
                title: "Posted Date",
                field: "PostedDateTime",
                type: "Date",
                escape: true,
                formatter: this.dateFormatter
            },
            {
                title: "Inmate",
                field: "InmateFullName",
                type: "Text",
                escape: true
            },
            {
                title: "BondApplicationID",
                field: "BondApplicationID",
                type: "Number",
                escape: true,
                class: "d-none",
                printIgnore: true
            },
            {
                title: "SO Number",
                field: "SONumber",
                type: "Text",
                escape: true
            },
            {
                title: "Booking",
                field: "BookNumber",
                type: "Text",
                escape: true
            }
        ];

        if (this._showCourtNameTemp) {
            this._tableColumns.push({
                title: "Court Name",
                field: "CourtName",
                type: "Text",
                escape: true
            });
        }

        this._tableColumns.push({
            title: "Offense Degree",
            field: "OffenseDegree",
            type: "Text",
            escape: true
        });

        this._tableColumns.push({
            title: "Offense Code",
            field: "OffenseCode",
            type: "Text",
            escape: true
        });

        this._tableColumns.push({
            title: "Offense",
            field: "OffenseDesc",
            type: "Text",
            escape: false
        });

        this._tableColumns.push({
            title: "Bond Number",
            field: "BondApplicationDetailID",
            type: "Number",
            escape: true
        });

        if (this._offensesHaveSpecialConditions) {
            this._tableColumns.push(
                {
                    title: "Special Conditions",
                    field: "SpecialConditions",
                    type: "Text",
                    escape: true
                }
            );
        }

        this._tableColumns.push(
            {
                title: "Bond Type",
                field: "BondType",
                type: "Text",
                escape: true,
            }
        );

        this._tableColumns.push({
                title: "Bond Amount",
                field: "BondAmount",
                type: "Number",
                escape: true,
                formatter: dollarFieldFormatter
        });

        this._tableColumns.push({
                title: "Bond Fee",
                field: "CountyBondFee",
                type: "Number",
                escape: true,
                formatter: dollarFieldFormatter
        });

        if (settings.featureFlags[FeatureFlag.AllowAmendingBondDocuments]) {
            this._tableColumns.push({
                title: 'Amended Date',
                field: 'AmendedDateTime',
                type: "Date",
                escape: true,
                formatter: this.dateFormatter
            });
            if (this._amendBondDocumentsPermission) {
                this._tableColumns.push({
                    title: 'Amend Bond Document',
                    field: 'AmendReportDocumentPlaceholder',
                    type: 'SafeHTML',
                    searchable: false,
                    escape: false,
                    formatter: this._amendDocumentFormatter,
                    printIgnore: true,
                    events: operateEvents
                });
            }
        }

        if (!this._preventBondCompaniesFromReceivingOrViewingSignedBondDocuments) {
            this._tableColumns.push({
                title: "Bond Document",
                field: "BondDocumentPlaceholder",
                type: "SafeHTML",
                searchable: false,
                escape: false,
                formatter: this._printPreviewFormatter,
                printIgnore: true
            });
        }

        this._tableColumns.push({
            title: "Electronic Power Document",
            field: "ElectronicPowerDocumentPlaceholder",
            type: "SafeHTML",
            searchable: false,
            escape: false,
            formatter: this._electronicPowerDocumentFormatter,
            printIgnore: true,
            events: operateEvents
        });

        if ((!this._isCountyUser || this._isSysAdmin) && this._hasBookingSheetFeatureFlag) {
            this._tableColumns.push({
                title: 'Booking Report',
                field: 'BookingReportDocumentPlaceholder',
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._bookingReportFormatter.bind(this),
                printIgnore: true,
                events: operateEvents
            });
        }
    }

    _printPreviewFormatter(value, row, index, field) {
        const bondDocumentId = row.BondDocumentId;
        return `<a class='btn btn-primary' href='Download/${bondDocumentId}' target='_blank'>Print Preview</a>`;
    }

    /**
     * 
     * @param {any} value
     * @param {import('../../types/Website/Bonds/Models/BondDocumentsPostedViewModel.ts').BondDocumentsPostedViewModel} row
     * @param {any} index
     * @param {any} field
     * @returns
     */
    _electronicPowerDocumentFormatter(value, row, index, field) {
        const powerDocumentId = row.PowerDocumentId;
        const powerDocumentGuid = row.PowerDocumentGuid;
        if (powerDocumentId === null)
            return "<button class='btn btn-primary' disabled>N/A</button>";
        else if (powerDocumentGuid === null)
            return `<action-button 
    class='btn btn-primary check-for-document'
    title='Electronic Power Document was not available at the time of bond bookout. Click here to check current availability.'
    alternatebuttontext='Loading'
    powerdocumentid=${powerDocumentId}
    trigger>Check for Document
</action-button>
<a class='btn btn-primary' powerdocumentid=${powerDocumentId} rel='noopener' href ='' target='_blank' hidden>Print Preview</a>`;
        else
            return `<a class='btn btn-primary' href='DownloadPower/${powerDocumentGuid}' target='_blank'>Print Preview</a>`;
    }
    /**
     * 
     * @param {any} value
     * @param {import('../../types/Website/Bonds/Models/BondDocumentsPostedViewModel.ts').BondDocumentsPostedViewModel} row
     * @param {any} index
     * @param {any} field
     * @returns
     */
    _bookingReportFormatter(value, row, index, field) {
        const jailID = row.JailID;

        let buttonString = `<div class='d-flex align-items-center justify-content-center'>`;
        buttonString += `<action-button class='btn btn-primary mr-4 view-booking' trigger alternatebuttontext='Loading Booking' jailId=${jailID} data-shadow-disabled>View Booking</action-button>`

        return buttonString;
    }

    _amendDocumentFormatter(value, row, index, field) {
        return `<action-button class='btn btn-primary amend-document' trigger alternatebuttontext='Loading Upload' data-shadow-disabled>Upload</action-button>`
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: "BondDocumentsPosted",
            tableColumns: this._tableColumns,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: "Bond-Documents-Booked-Out",
            reportUrl: "/Reports/BondPosted_Read",
            restoreFilters: true,
            tableName: "BondDocumentPosted",
            tableClasses: "table-striped",
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };

        this._setupTableEventListeners();
    }

    _checkForDocumentOnClick(event) {
        this._pageTools.toggleTriggers(this._table, true);
        this._errorMessage.toggleAttribute("hidden", true);
        this._clearErrorRowStyle();
        const buttonElement = event.currentTarget;
        const powerDocumentId = Number(buttonElement.getAttribute("powerdocumentid"));
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/Reports/CheckPowerDocument",
            { id: powerDocumentId },
            this._checkPowerDocumentCallback.bind(this, buttonElement));
    }

    _setupTableEventListeners() {
        this._table.addEventListener('viewClick', e => this._viewBooking(
            (e.detail !== undefined && e.detail.event !== undefined) ? e.detail.event : {}
        ));
    }

    _viewBooking(event) {
        const currentTarget = this._getCurrentTargetAndToggleTriggers(event);
        const xhrWrapper = new XhrWrapper();

        const viewBookingSyncCallback = this._viewBookingSyncCallback.bind(this, currentTarget);

        xhrWrapper.makeRequest('POST',
            '/Application/SyncInmateJailing',
            { jailId: this._selectedJailId },
            viewBookingSyncCallback
        );
    }

    _viewBookingSyncCallback(buttonElement, response, isSuccess) {
        this._pageTools.toggleTriggers(this._table, false);
        this._pageTools.openLinkInNewTab(`/Roster/BookingSheet/Index/${this._selectedJailId}`);
    }

    _getCurrentTargetAndToggleTriggers(event) {
        const target = event.currentTarget;
        const jailId = target.getAttribute('jailid');
        this._pageTools.toggleTriggers(this._table, true);
        this._selectedJailId = jailId;
        return target;
    }

    _clearErrorRowStyle() {
        const rows = this._table.querySelectorAll("tr");
        rows.forEach(row => {
            row.classList.toggle("charge-status-hold-without-bond", false);
        });
    }

    _checkPowerDocumentCallback(buttonElement, response, isSuccess) {
        this._pageTools.toggleTriggers(this._table, false);
        const responseObj = this._pageTools.tryParseJson(response);
        if (!isSuccess || !responseObj) {
            this._displayCheckPowerDocumentFailure(buttonElement);
            return;
        }
        const anchorElement = buttonElement.nextElementSibling;
        anchorElement.href = `DownloadPower/${responseObj}`;
        buttonElement.toggleAttribute("hidden", true);
        anchorElement.toggleAttribute("hidden", false);
    }

    _displayCheckPowerDocumentFailure(buttonElement) {
        this._errorMessage.innerHTML =
            "There was a problem retrieving the Electronic Power Document you requested." +
            ' Please try again, and if the problem persists, <u id="contact-support" onclick="contactUsModal.openModal()" role="button">contact eBONDS™ Support</u>.' +
            ' Click <u id="return-to" role="button">here</u> to return to the affected entry.';
        this._errorMessage.toggleAttribute("hidden", false);
        const returnToButton = this._errorMessage.querySelector("#return-to");
        returnToButton.addEventListener("click", () => {
            this._pageTools.scrollTo({
                top: buttonElement.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2), //scroll the element to the center of the screen
                behavior: "smooth"
            }); //Element.scrollIntoView(scrollIntoViewOptions) is not supported on IOS :(
        });
        const parentRow = buttonElement.closest("tr");
        parentRow.classList.add("charge-status-hold-without-bond");
        this._pageTools.scrollTo({ top: 0, behavior: "smooth" });
    }

    dateFormatter(value) {
        return window.DateFormatter.getTenantFormattedDate(value);
    }
}

customElements.define("bond-document-posted-grid", BondDocumentsPostedGrid);