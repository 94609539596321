// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum FeatureFlag {
  UseFingerPrintScanner = 0,
  UseDemoFeatures = 1,
  BookoutCompleteShowsAllCharges = 2,
  PowersOfAttorneyReport = 3,
  PoaBanner = 4,
  PRBondStrictValidation = 5,
  ContinueOnSyncFailure = 6,
  CourtInformationHasAgencyCaseNumber = 7,
  RestrictCardsTemp = 8,
  BondCompaniesHaveFullRosterAccess = 9,
  AcceptsAllPaidPayments = 10,
  CountyUserHasAllPaidNavMenuItem = 11,
  HasMugshot = 12,
  HasPerOffenseFee = 13,
  BondTypeSurchargeScheduleTemp = 14,
  CourtInformationRequiredForCountyUsers = 15,
  CourtInformationOptionalForCountyUsers = 16,
  CourtInformationRequiredForBondUsers = 17,
  CourtInformationOptionalForBondUsers = 18,
  OffensesHaveSpecialConditions = 19,
  PrBondHasIssuingAuthority = 20,
  HaysConfirmNoHoldsTemp = 21,
  OutOfCountyBondTemplateTemp = 22,
  DetailedManageUsersTableTemp = 23,
  BondDocumentTemplatesAreAssignablePerOffense = 24,
  ApplicationRefreshTemp = 25,
  BondTypeEnforcementTemp = 26,
  ReceiptTemplateUploadTemp = 27,
  ExtraditionInformationTemp = 28,
  BondCompanyMugshotVerify = 29,
  SendEmailToBondCompanyOnApplicationSubmitted = 30,
  SendEmailToBondCompanyOnApplicationReviewed = 31,
  AllPaidDisablePartialPayments = 32,
  PreventBondCompaniesFromReceivingOrViewingBondDocuments = 33,
  CourtInformationCauseNumberRequired = 34,
  CourtInformationWarrantNumberRequired = 35,
  CaptureFeesAtEndOfReviewProcess = 36,
  AttorneyBarNumberExpirationRequired = 37,
  PreviewAllFeatureFlagTemp = 38,
  HasBookingSheet = 39,
  ReviewAuthorizationRequiredTemp = 40,
  FullApiInmateAccess = 41,
  RefreshOnCadence = 42,
  TenantWidePoaReportTemp = 43,
  EnforceBondApplicationLimitsTemp = 44,
  BondDocumentCertificationTemp = 45,
  ShortcutBookoutQueueProcessTemp = 46,
  RequireAuthorizationSignatureAtBookout = 47,
  BondCompaniesOnlyGetUnofficialBondDocuments = 48,
  ExpandUserRolesTemp = 49,
  ConfigurableTimeoutTemp = 50,
  SingleSessionSignOnTemp = 51,
  RestrictIssuingAuthority = 52,
  CollateralLimitEnforcement = 53,
  OffensesHaveVerboseSpecialConditions = 54,
  TotalBonds = 55,
  UserLevelPoaLicense = 56,
  HideMugshotsForJailAccounts = 57,
  HideMugshotsForCompanyAccounts = 58,
  HideMugshotsForPaymentPortal = 59,
  ShowPaymentMethod = 60,
  StoreCompaniesAssignedToUserInDatabaseTemp = 61,
  AllowPaymentPortalInmateReleaseNotification = 62,
  PaymentPortalPlcCheckTemp = 63,
  NotifyTenantOfDataProcessingIssuesTemp = 64,
  PaymentPortalRequiresPayorIdentifyingInformation = 65,
  ShowBookingReportOfficialUseOnlyText = 66,
  DataEntryErrorLogicTemp = 67,
  UpdateFeeCalculationTemp = 68,
  ShowBookingReportSpecialConditions = 69,
  BondDocumentsBookedOutReportShowsCourtNameTemp = 70,
  RequireAdditionalAuthorizationSignatureAtBookout = 71,
  CourtNameShowsForBondApplication = 72,
  CourtInformationReadOnly = 73,
  EnforceOwnerBondCompanyCollateralLimit = 74,
  HideAdditionalInmateInformationInputs = 75,
  OptionalParcelNumber = 76,
  AttorneyCompaniesTemp = 77,
  ExtendedAllPaidValidationTemp = 78,
  BondPaymentCaptureCourtTemp = 79,
  CollectInmateContactInformation = 80,
  CollectInmateContactEmailAddress = 81,
  CollectInmateContactEmailAddressRequired = 82,
  SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp = 83,
  AllowAmendingBondDocuments = 84,
  CancelAmendedBondDocumentsTemp = 85
}